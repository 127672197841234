import { Dom } from "../../assets/js/helpers/dom";
import { Utils } from "../../assets/js/helpers/utils";
export class Accordion {

  static cssClass = "c-accordion";

  constructor($el = null) {
    this.$el = $el;
    this.elAccordion = $($el).find("[data-accordion]");
    this.init();
  }

  init() {

    this.elAccordion.find("[data-item]").each(function () {
      var current_accordion = $(this).parent(["data-accordion"]);
      var content = $(this).find("[data-content]");
      $(this).find("[data-content-trigger]").click(function () {
          // content.stop().slideToggle();
          content.stop().slideToggle()
          current_accordion.toggleClass("active");
      });
  });
}
}
