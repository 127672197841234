//Vendors
import { Modal } from "bootstrap";

// Common
import { default as init } from "./common/base";

// Helpers
import { Component } from "./helpers/component";

// Components
import { Slider } from "../../components/slider/slider";
import { Accordion } from "../../components/accordion/accordion";
import { HeroVideo } from "../../components/herovideo/herovideo";
import { Menu } from "../../components/menu/menu";

// Registering components
Component.register("Slider", Slider);
Component.register("Accordion", Accordion);
Component.register("Menu", Menu);
Component.register("HeroVideo", HeroVideo);

init();

document.addEventListener('DOMContentLoaded', () => {

  $(`.c-menu__nav--item-trigger`).click(function(){
    event.preventDefault();
    var itemtrigger = $(this).parents(`.c-menu__nav--item`);
    if (!itemtrigger.hasClass('open')){
      $(`.c-menu__nav--item`).removeClass('open');
      itemtrigger.addClass('open');
    }
    else {
      itemtrigger.removeClass('open');
    }

  });

  $(`.c-nav3__tab--item`).click(function(){
    event.preventDefault();
    if (!$(this).hasClass('is-active')){
      $(`.c-nav3__tab--item`).removeClass('is-active');
      $(this).addClass('is-active');
    }
  });


  $(`.c-card1__profile--bio--foot`).click(function(){
    if ($(this).parents(`.c-card1__profile`).hasClass('active')){
      $(this).parents(`.c-card1__profile`).removeClass('active');
    }
    else {
      $(this).parents(`.c-card1__profile`).addClass('active');
    }
  });
  $(`.c-card1__investitor--foot`).click(function(){
    if ($(this).parents(`.c-card1__investitor`).hasClass('active')){
      $(this).parents(`.c-card1__investitor`).removeClass('active');
    }
    else {
      $(this).parents(`.c-card1__investitor`).addClass('active');
    }
  });
  var viewportWidth = $(window).width();


// Anchor Navigation Newsletter
$('a[data-value="FooterNewsletter"]').click(function(){
  event.preventDefault();
  var offset = $('.c-footer').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 100;
  }
  else{
  var scrollto = offset.top - 110
}
  $('html, body').animate({scrollTop:scrollto}, 1000);
});

// Anchor Navigation Page Project
// $('a[data-value*=]').click(function(){
//   $attr = $(this).data('value');
//   console($attr);
//   if ( $attr ) {
//   event.preventDefault();
//   var offset = $($attr).offset();
//   if (viewportWidth < 992) {
//     var scrollto = offset.top - 100;
//   }
//   else{
//   var scrollto = offset.top - 110
// }
//   $('html, body').animate({scrollTop:scrollto}, 1000);
//   }
// });

$('a[data-value="Projects"]').click(function(){
  event.preventDefault();
  var offset = $('#Projects').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 100;
  }
  else{
  var scrollto = offset.top - 110
}
  $('html, body').animate({scrollTop:scrollto}, 1000);
});

$('a[data-value="Studying"]').click(function(){
  event.preventDefault();
  var offset = $('#Studying').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 100;
  }
  else{
  var scrollto = offset.top - 110
}
  $('html, body').animate({scrollTop:scrollto}, 1000);
});

$('a[data-value="Thesis"]').click(function(){
  event.preventDefault();
  var offset = $('#Thesis').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 100;
  }
  else{
  var scrollto = offset.top - 110
}
  $('html, body').animate({scrollTop:scrollto}, 1000);
});

$('a[data-value="Experiment"]').click(function(){
  event.preventDefault();
  var offset = $('#Experiment').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 100;
  }
  else{
  var scrollto = offset.top - 110
}
  $('html, body').animate({scrollTop:scrollto}, 1000);
});

$('a[data-value="Meeting"]').click(function(){
  event.preventDefault();
  var offset = $('#Meeting').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 100;
  }
  else{
  var scrollto = offset.top - 110
}
  $('html, body').animate({scrollTop:scrollto}, 1000);
});

$('a[data-value="Comunication"]').click(function(){
  event.preventDefault();
  var offset = $('#Comunication').offset();
  if (viewportWidth < 992) {
    var scrollto = offset.top - 100;
  }
  else{
  var scrollto = offset.top - 110
}
  $('html, body').animate({scrollTop:scrollto}, 1000);
});


});


